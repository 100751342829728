<template>
    <div>
        <Header></Header>
        <div class="main-content">
            <div class="article-info">
                <div class="article-content f18 mb20" >
                    {{dynamic.content || ''}}
                </div>
                <div class="img-box1" v-if="dynamic.urls.length>3">
                    <img v-for="(item,index) in dynamic.urls" :key="item.title" :preview="item.preview" :preview-text="item.title" :src="item.url" :alt="item.title" >
                </div>
                <div class="img-box2" v-if="dynamic.urls.length==2">
                    <img v-for="(item,index) in dynamic.urls" :key="item.title" :preview="item.preview" :preview-text="item.title" :src="item.url" :alt="item.title" >
                </div>
                <div class="img-box2" v-if="dynamic.urls.length==1">
                    <img v-for="(item,index) in dynamic.urls" :key="item.title" :preview="item.preview" :preview-text="item.title" :src="item.url" :alt="item.title" >
                </div>
            </div>
            <div class="more-toolbox-new">
                <div class="toolbox">
                    <div class="left-img">
                        <img :src="config.logoPhoto ? config.logoPhoto : '/static/images/personal/default.png'" alt="">
                        <span class="profile-name">{{config.miniApps || ''}}</span>
                    </div>
                    <div class="right-fun">
                        <div class="right-fun-item cup" @click.stop="addDynamic(dynamic.id,dynamic.mid)">
                            <i class="iconfont icon-thumbup-fill mr5 "  style="font-size: 22px;" :class="dynamic.isPraise!=0 ? 'on' : ''"></i>
                            {{dynamic.praiseNum>=10000?changeUnits(dynamic.praiseNum):dynamic.praiseNum}}
                        </div>
                        <div class="right-fun-item ml15">
                            <i class="iconfont icon-pinglun mr5"></i>
                            {{dynamic.commentNum > 10000 ? changeUnits(dynamic.commentNum) : dynamic.commentNum || 0}}
                        </div>
                        <div class="right-fun-item ml5">
                            <div class="gap-row"></div>
                            <i class="iconfont icon-fenxiang8 ml5"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="CommentBox">
            <div class="comment-title">
                全部留言({{dynamic.commentNum > 10000 ? changeUnits(dynamic.commentNum) : dynamic.commentNum || 0}})
            </div>
            <!-- 发表评论 -->
            <div class="comment_box">
                <input v-model="comment_input" placeholder="请发表友善的评论" @keyup.enter="evaluate" />
                <el-button type="danger" round @click="evaluate">发表回复</el-button>
            </div>
            <!-- 评论列表 -->
            <div class="comment-list">
                <div class="comments-detail" v-for="(item,index) in list">
                    <div class="comments-detail-user">
                        <img :src="item.evaluatorsUrl?item.evaluatorsUrl:'/static/images/personal/default.png'" alt="">
                        <span class="comments-detail-user-info">{{item.evaluatorsName}}</span>
                        <span v-if="item.replyId" class="f15 ml10">回复</span> <span class="ml5 hf f15 ml10" v-if="item.replyId">{{item.replyNickName || ''}}</span>	
                    </div>
                    <div class="comments-detail-content">{{item.content}}</div>
                    <div class="comments-detail-opt">
                        <div class="comments-detail-opt-item">
                            {{TimeFormat({date:item.createDate,fmt:'MM-dd hh:mm'})}}
                        </div>
                        <div class="comments-detail-opt-item" @click.stop="click_user(item.evaluatorsName,item.id,index,item.replyId,item.replyNickName,item)">
                            <i class="iconfont icon-xiaoxi4"  v-if="!item.showReply"></i>
                            <i class="iconfont icon-xiaoxi4" v-if="item.showReply" style="color: var(--color);"></i>
                            <span v-if="!item.showReply">回复</span>
                            <span v-if="item.showReply" style="color: var(--color);">取消回复</span>
                        </div>
                        <div class="comments-detail-opt-item delcomment" v-if="item.evaluatorsId == userInfo.id"  @click="del_eves(item.id,index)">
                            <i class="iconfont icon-lajixiang"></i>
                            <span >删除</span>
                        </div>
                    </div>
                    <div class="comment_box" v-if="item.showReply">
                        <input v-model="content" :placeholder="nickName" @keyup.enter="evaluate" />
                        <el-button type="danger" round @click="evaluate">发表回复</el-button>
                    </div>
                    <!-- <div class="sub-comments">
                        <div>
                            <div class="comments-detail-user">
                                <img src="https://tvax3.sinaimg.cn/crop.0.0.600.600.180/006QfZJjly8h0at2l92z2j30go0godhd.jpg?KID=imgbed,tva&Expires=1704364121&ssig=JzYWXDXgbo" alt="">
                                <span class="user-name">黄佳</span>
                                <span class="user-tips">讲师</span>
                            </div>
                            <div class="comments-detail-content">对的，主要框架是《零基础实战机器学习》。应该是有添加一部分的内容。</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <gap height="50"></gap>
        <Homefooter ></Homefooter>
    </div>
</template>

<script>
    import { dynamicDetails ,addDynamicPraise,DylistComments,dongtai_comment,cdelComments} from "@/apiUrl/index.js";
    import { mapGetters } from "vuex"
    export default {
        computed: {
		  ...mapGetters(["userInfo", "allUserInfo","base_bgColor","config","share_text"]),
		},
        data() {
            return {
                dpList:[],
                list:[],
                dynamic:{headPhoto:{}},
                did:'',
                comment_input:'',
                currentPage:1,
                nickName:'说点什么...',
                replyId:'',
                index:'',
                repId:'',
                setFocus:'',
                content:'',
            }
        },
        created() {
            let {id} = this.$route.query;
            this.did = id;
            this.getdata();
            this.getlist();
        },
        methods:{
            del_eves:function(id,index){
                this.$confirm(`你确定要删除该评论吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        if(!id){
                            this.$msg('请刷新页面后再进行删除!',2)
                            return
                        }
                        var el = this;
                        cdelComments({id}).then((e)=>{
                            console.log(e)
                            if(e.code==200){
                                this.$msg('删除成功')
                                el.list.splice(index, 1);
                            }else{
                                this.$msg(e.message,2)
                            }
                        })
                })     
				  
			},
            evaluate:function(){//回复评价
				var el = this;
				if(!el.content) {
					this.$msg("评论内容不能为空！")
					return
				}
				dongtai_comment({
					dynamicId:this.did,					//动态id
					content:el.content,					//评论内容
					replyId:el.replyId					//回复人id,没有就是评价
				}).then((e)=>{
					if(e.code==200){
						this.$msg("评论成功");
						if(el.replyId.length==0){
							el.getdata();
							var data={
								evaluatorsUrl:el.userInfo.headPortraitUrl,
								evaluatorsName:el.userInfo.nickname,
								content:el.content,
								addTime:el.TimeFormat({fmt:'yyyy-MM-dd hh:mm:ss'}),
								evaluatorsId:el.userInfo.id,
								id:e.data
							}
							console.log('data',data)
							el.list.push(data);
						}else{
							var data={
								evaluatorsUrl:el.userInfo.headPortraitUrl,
								evaluatorsName:el.userInfo.nickname,
								replyId:el.repId,
								replyNickName:el.repName,
								content:el.content,
								addTime:el.TimeFormat({fmt:'yyyy-MM-dd hh:mm:ss'}),
								evaluatorsId:el.userInfo.id,
								id:e.data
							}
							console.log('data',data)
							el.list.splice([el.index + 1],0,data);
							
						}
						el.content = '';
                        el.list.some((item,index) => {
                            if(index == el.index) {
                                this.$set(item,'showReply',false)
                                return true
                            }
                        })
					}else{
                        this.$msg(e.message,2)
					}
				})
			},
            click_user:function(isname,id,index,repId,repName,item){
                if(!item.showReply) {
                    this.nickName = '对'+isname+'说:';
                    this.replyId = id;
                    this.index = index;
                    this.repId = repId;
                    this.repName = repName;
                    this.setFocus = true; 
                    this.$set(item,'showReply',true)
                } else {
                    this.$set(item,'showReply',false)
                }
				
			},
            // 点赞动态
			addDynamic:function(id,mid,index){
				var el = this;
				addDynamicPraise({did:id}).then((e)=>{
					if(e.code==200){
						this.showMore = false;
						if(el.dynamic.praiseCount<3){
							el.getdata();
						}else{
							if(el.dynamic.isPraise==0){
								el.dynamic.isPraise=1;
								el.dynamic.praiseCount++;
							}else{
								el.dynamic.isPraise=0;
								el.dynamic.praiseCount--;
							}
						}
						
					}else{
                        this.$msg(e.message,2)
					}
				})
			},
            getlist:function(){//评价列表分页
				var el = this;
				DylistComments({
					current: this.currentPage,
					size:999,
					dynamicId: this.did,
					parentId: ""
				}).then((e)=>{
					if(e.code==200){
						var arr = e.data.list;
						arr.forEach(function(v,i){
							v.son = [];
							v.currentPage = 1;
							v.isOK = false;
						})
						el.list = el.list.concat(arr);
						
					}
				})
				
			},
            getdata:function(){
				dynamicDetails({
					did:this.did,					//动态id
					praiseSize:"23",				//点赞人展示数量
				}).then((e)=>{
					if(e.code==200){
						var dynamic = e.data.dynamic;
						dynamic.urls = this.spmeth(dynamic.urls);
						this.dynamic = dynamic;
						this.dpList = e.data.dpList;
                        this.$previewRefresh();
					}
				})
			},
            changeUnits(num) {
				let val = num/10000;
				val = val.toFixed(1);
				return val + '万'
			},
            spmeth:function(e){
				var a = e.split(",");
                let list = [];
                a.forEach((item,index) => {
                    list.push({
                        url:item,
                        title:'图片' + (index+1),
                        preview:1
                    })
                })
				return list
			},
            changeTime(time) {
				var current_time = new Date(this.$TimeFormat({fmt:'yyyy/MM/dd hh:mm:ss',date:time})).getTime();//当前时间戳
				return this.$timeago(current_time);
			},
            TimeFormat:function(e){
				return this.$TimeFormat(e)
			},
        }
    }
</script>

<style lang="less" scoped>
.hf {
    color: var(--color);
}
.main-content {
    width: 1000px;
    margin: 20px auto;
    background-color: #fff;
    
    border-radius: 2px 2px 0 0;
}
.article-info {
    padding: 0 24px 16px;
}
.title-article {
    padding-top: 8px;
    font-size: 28px;
    word-wrap: break-word;
    color: #222226;
    font-weight: 600;
    margin: 0;
    word-break: break-all;
}
.article-info-box {
    display: flex;
    align-items: center;
    position: relative;
    background: #f8f8f8;
    border-radius: 4px;
    padding: 15px;
    color: #999aaa;
}
.author-article:hover {
    color: #fc5531;
}
.article-content {
    padding-top: 16px;
}
.more-toolbox-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-bottom: 8px;
    background-color: #fff;
    height: 64px;
    box-shadow: 0 -1px 8px 0 rgb(0 0 0 / 6%);
    padding: 17px 24px;
}
.toolbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.left-img {
    display: flex;
    align-items: center;
    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
    }
    span {
        max-width: 160px;
        height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 600;
        color: #222226;
        line-height: 24px;
        margin-right: 8px;
    }
}
.right-fun {
    display: flex;
    align-items: center;
    color: #999aaa;
    .right-fun-item {
        display: flex;
        align-items: center;
    }
    .iconfont {
        font-size: 20px;
    }   
    .gap-row {
        width: 1px;
        height: 16px;
        background: #f0f0f2;
        margin: 0 8px;
        padding: 0;
    }
}
.comment_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f8f9;
    margin-top:15px;
    margin-bottom:15px;
    input {
        background-color: #f7f8f9;
        border: none;
        height: 40px;
        width: 100%;
        outline: none;
        padding: 0 15px;
    }
    input:focus {
        border: none;
    }
}
.comments-detail {
    margin-bottom: 20px;
    padding-top: 10px;
    border-bottom: 1px solid #f2f2f5;
    
}
.CommentBox {
    width: 1000px;
    margin: 20px auto;
    border-radius: 2px 2px 0 0;
    padding: 15px;
    background-color: #fff;
    .comment-title {
        font-size: 16px;
    }
    .comments-detail-user {
        display: flex;
        align-items: center;
        img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            cursor: pointer;
        }
        .comments-detail-user-info {
            font-weight: 700;
            font-size: 16px;
            margin-left: 15px;
            cursor: pointer;
        }
    }
    .comments-detail-content {
        font-size: 14px;
        color: #555770;
        line-height: 22px;
        padding-left: 52px;
        margin-bottom: 10px;
        word-break: break-all;
    }
    .comments-detail-opt {
        display: flex;
        padding-left: 52px;
        gap: 16px;
        color: #777888;
        line-height: 20px;
        margin-bottom: 16px;
        .comments-detail-opt-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 4px;
        }
    }
    .sub-comments {
        margin-left: 52px;
        margin-bottom: 32px;
        padding: 20px;
        border-bottom: none;
        background: #f7f8fa;
        .comments-detail-user {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            margin-bottom: 0;
            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
            .user-name {
                font-size: 15px;
                font-weight: 700;
                color: #1c1c28;
            }
            .user-tips {
                display: inline-block;
                width: 48px;
                height: 20px;
                font-size: 14px;
                color: #0076ff!important;
                line-height: 20px;
                text-align: center;
                background: #e5f1ff;
                border-radius: 2px;
            }
        }
        .comments-detail-content {
            font-size: 14px;
            color: #555770;
            line-height: 22px;
            padding-left: 42px;
            margin-bottom: 10px;
            word-break: break-all;
        }
    }
}
.on {
    color: var(--color) !important;
}
.img-box1 {
    width: 630px;
    img {
        width: 200px;
        height: 200px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: zoom-in;
    }
}
.img-box2 {
    width: 630px;
    img {
        width: 300px;
        height: 300px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: zoom-in;
    }
}
</style>